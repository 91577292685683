import React, { useEffect, useContext } from "react";
import { useBoolean } from "ahooks";
import { Breadcrumb, Button, Flex, FloatButton, Table, Typography } from "antd";
import { Link, useParams } from "react-router-dom";

import ImageWithBoundingBox from "components/admin/show/ImageWithBoundingBox";
import JsonModal from "components/reusable/JsonModal";
import ModalWrapper from "components/reusable/web_links/ModalWrapper";
import {
  createInitialEnvironment,
  DisplayEnvironment,
  getEnvironment,
} from "contexts/displayEnvironment";
import { useJob, useJobFileLinks } from "queries";
import type {
  Job,
  JobDetails,
  JobImage,
  JobLog,
  JobRef,
  JobReview,
  JobWalletTransaction,
} from "types/api/job";
import { biometricJobTypes, jobTypeToProductNameMap } from "util/selectors";
import ReRun from "./re_run";

function DisplayTitle({
  environment,
  jobId,
  jobType,
  partnerId,
  productType,
  sdk,
  sdkVersion,
}: {
  environment: string;
  jobId: string;
  jobType: number;
  partnerId: string;
  productType: string;
  sdk?: string;
  sdkVersion?: string;
}) {
  return (
    <>
      <Typography.Title level={1} className="h1">
        Partner {partnerId} Job{" "}
        <Link
          className="title-link"
          to={`/admin/${partnerId}/job_results/${environment}/${jobId}`}
        >
          {jobId}
        </Link>
        {jobType && ` - ${jobTypeToProductNameMap[productType]} JT${jobType}`}
      </Typography.Title>
      {sdk && sdkVersion && (
        <Typography.Paragraph>
          SDK: {sdk} v{sdkVersion}
        </Typography.Paragraph>
      )}
    </>
  );
}

function DisplayLogs({ logs = [] }: { logs: JobLog[] | null }) {
  if (!logs || !Array.isArray(logs))
    return <Typography.Paragraph>No logs available.</Typography.Paragraph>;
  if (logs.length === 0)
    return <Typography.Paragraph>No logs to display.</Typography.Paragraph>;

  return logs.map((log) => {
    const {
      id,
      ref_id,
      created_at: createdAt,
      partner_params: partnerParams,
    } = log;

    return (
      <React.Fragment key={id}>
        <Typography.Paragraph>
          <strong>Ref:</strong> {ref_id}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Created at:</strong> {new Date(createdAt).toLocaleString()}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Job Type:</strong> {partnerParams.job_type}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>User ID:</strong> {partnerParams.user_id}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Job ID:</strong> {partnerParams.job_id}
        </Typography.Paragraph>
        <Typography.Paragraph className="overflow-auto">
          <strong>Partner params:</strong> {JSON.stringify(partnerParams)}
        </Typography.Paragraph>
        <JsonModal jsonValue={log} />
      </React.Fragment>
    );
  });
}

function DisplayReferences({ references = [] }: { references: JobRef[] }) {
  if (!references || references.length === 0) {
    return (
      <Typography.Paragraph>No references available.</Typography.Paragraph>
    );
  }

  return references.map((reference) => (
    <React.Fragment key={`reference-${reference.id}`}>
      <div>
        <Typography.Paragraph>
          <strong>Status:</strong> {reference.status}
        </Typography.Paragraph>
        <Typography.Paragraph className="overflow-auto">
          <strong>Result Text:</strong>{" "}
          {JSON.stringify(
            reference.result.ResultText || reference.result.message,
          )}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Result Code:</strong>{" "}
          {reference.result.ResultCode || reference.result.code}
        </Typography.Paragraph>
        <Typography.Paragraph>
          <strong>Created at:</strong>{" "}
          {new Date(reference.created_at).toLocaleString()}
        </Typography.Paragraph>
        <div className="mt-4 mb-8">
          <JsonModal jsonValue={reference} />
        </div>
      </div>
    </React.Fragment>
  ));
}

function DisplayMLResults({
  mlResults,
}: {
  mlResults: null | Record<string, { result_code: number }>;
}) {
  if (!mlResults) {
    return (
      <Typography.Paragraph>No ML results available.</Typography.Paragraph>
    );
  }

  return Object.keys(mlResults).map((key) =>
    key !== "ref_id" ? (
      <div key={key}>
        <Typography.Paragraph>
          <strong>{key.replace("_", " ")} result code:</strong>{" "}
          {Math.round(mlResults[key].result_code)}
        </Typography.Paragraph>
        <div className="mt-4 mb-8">
          <JsonModal jsonValue={mlResults[key]} />
        </div>
      </div>
    ) : null,
  );
}

function DisplayRelatedJobs({
  relatedJobs = [],
}: {
  relatedJobs: Job["related_jobs"];
}) {
  return (
    <div className="grid gap-6 grid-cols-[max-content_auto]">
      {relatedJobs.map((relatedJob) => (
        <React.Fragment key={relatedJob.id}>
          <Typography.Paragraph className="mb-0 self-center">
            {`Partner Job ID: ${relatedJob.job_id} - `}
            {`${jobTypeToProductNameMap[relatedJob.product_type]} JT${
              relatedJob.partner_params.job_type
            }`}
            {` - ${new Date(relatedJob.created_at).toLocaleString()}`}
          </Typography.Paragraph>
          <div>
            <Link
              to={`/admin/job/${getEnvironment()}/${relatedJob.id}${window.location.search}`}
              component={Button}
              type="primary"
            >
              Show
            </Link>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
}

function DisplayFiles({
  fileLinks = [],
}: {
  fileLinks: { name: string; url: string }[];
}) {
  return (
    <ul className="list-none">
      {fileLinks
        .sort((a, b) => a.name.localeCompare(b.name))
        ?.map((file, index) => (
          <li key={index}>
            {file.url ? (
              <a href={file.url} target="_blank" rel="noreferrer">
                {file.name}
              </a>
            ) : (
              <span className="text-products-lightred">
                {`${file.name} - not found`}
              </span>
            )}
          </li>
        ))}
    </ul>
  );
}

const humanReadableImageTypes = Object.freeze({
  0: "Selfie",
  1: "Document",
  2: "Selfie",
  3: "Document",
  4: "Liveness",
  5: "Document Back",
  6: "Liveness",
  7: "Document Back",
  8: "Misc Document",
  9: "ID Authority",
  10: "Misc Document",
  11: "ID Authority Document",
  12: "Potrait",
});

const imageGroups = Object.freeze({
  Document: [1, 3, 5, 7, 8, 10, 11],
  "Selfie and ID Authority": [0, 2, 9],
  Liveness: [4, 6],
  Potrait: [12],
});

function DisplayImages({
  images = [],
  boundingBox,
  mlResults,
}: {
  images: JobImage[];
  boundingBox?: number[];
  mlResults?: Job["ml_results"];
}) {
  const groupedImagesByType: Record<string, JobImage[]> = {};
  Object.entries(imageGroups).forEach(([groupName, groupImageTypes]) => {
    const subset = images
      .filter((image) => groupImageTypes.includes(image.image_type))
      .sort((a, b) =>
        `${a.image_type}-${a.file_name}-${a.enrolled_image}`.localeCompare(
          `${b.image_type}-${b.file_name}-${b.enrolled_image}`,
        ),
      );
    if (subset.length > 0) {
      groupedImagesByType[groupName] = subset;
    }
  });

  const ungroupedImages = images.filter(
    (image) => !Object.values(imageGroups).flat().includes(image.image_type),
  );

  if (ungroupedImages.length > 0) {
    groupedImagesByType.Ungrouped = ungroupedImages.sort((a, b) =>
      a.file_name.localeCompare(b.file_name),
    );
  }

  return (
    <>
      {Object.entries(groupedImagesByType).map(([groupName, imageGroup]) => (
        <div key={groupName}>
          <Typography.Title className="h3" level={3}>
            {groupName} Images
          </Typography.Title>
          <Flex wrap gap="large">
            {imageGroup.map((image) => {
              const imagePath = new URL(image.link).pathname.substring(1);
              const isSameS3Path =
                mlResults?.passive_liveness?.metadata?.s3_path === imagePath;

              return (
                <div
                  key={`${image.link}${image.enrolled_image}`}
                  className="relative"
                >
                  <div className="mb-2">
                    {[0, 2].includes(image.image_type) && boundingBox ? (
                      <ImageWithBoundingBox
                        boundingBox={boundingBox}
                        url={image.link}
                        className="image max-h-72"
                      />
                    ) : (
                      <img
                        src={image.link}
                        alt={image.file_name}
                        className="image max-h-72 object-contain"
                      />
                    )}
                  </div>

                  <div className="text-center">
                    {image.enrolled_image ? (
                      <strong>Enrolled Image:</strong>
                    ) : null}{" "}
                    {humanReadableImageTypes[image.image_type as 0] ??
                      image.image_type}{" "}
                    -{" "}
                    <a href={image.link} rel="noreferrer" target="_blank">
                      {image.file_name}
                    </a>
                    {isSameS3Path && <strong> (Matched S3 Path)</strong>}
                  </div>
                </div>
              );
            })}
          </Flex>
        </div>
      ))}
    </>
  );
}

function DisplayReviews({ reviews = [] }: { reviews: JobReview[] }) {
  // Sort reviews by updated_at in descending order
  const sortedReviews = [...reviews].sort(
    (a, b) =>
      new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime(),
  );

  return (
    <div>
      {sortedReviews.length === 0 ? (
        <Typography.Paragraph>No reviews available.</Typography.Paragraph>
      ) : (
        <Table
          columns={[
            {
              title: "Purpose",
              dataIndex: "purpose",
              render: (_, review) =>
                review.complete ? (
                  <a
                    href={`/admin/reviews/${review.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {review.purpose}
                  </a>
                ) : (
                  review.purpose
                ),
            },
            {
              title: "Updated At",
              dataIndex: "updated_at",
              render: (_, { updated_at }) =>
                new Date(updated_at).toLocaleString(),
            },
            {
              title: "Duration",
              dataIndex: "duration",
              render: (_, { duration, complete }) =>
                complete ? duration : "-",
            },
            {
              title: "Author",
              dataIndex: "author",
              render: (_, { author }) => author || "N/A",
            },
            {
              title: "Status",
              render: (_, { complete }) =>
                complete ? "Complete" : "Incomplete",
            },
          ]}
          dataSource={sortedReviews.map((review) => {
            const createdAt = new Date(review.created_at);
            const updatedAt = new Date(review.updated_at);
            const durationMilliseconds =
              updatedAt.getTime() - createdAt.getTime();
            const durationMinutes = Math.floor(durationMilliseconds / 60000);
            const durationSeconds = (
              (durationMilliseconds % 60000) /
              1000
            ).toFixed(0);
            const duration =
              durationMinutes > 0
                ? `${durationMinutes}m ${durationSeconds}s`
                : `${durationSeconds}s`;

            return {
              ...review,
              duration,
            };
          })}
          pagination={false}
        />
      )}
    </div>
  );
}

function DisplayCharges({
  walletTransaction,
}: {
  walletTransaction: JobWalletTransaction;
}) {
  return (
    <>
      <Typography.Paragraph>
        Amount: {walletTransaction.amount}
      </Typography.Paragraph>
      <Typography.Paragraph>
        Balance After Transaction: {walletTransaction.current_balance}
      </Typography.Paragraph>
      <Typography.Paragraph>
        Billed Codes: {walletTransaction.result_codes.join(", ") || ""}
      </Typography.Paragraph>
      <Typography.Paragraph>
        Provider Cost: {walletTransaction.provider_cost || 0}
      </Typography.Paragraph>
    </>
  );
}

function DisplayReRunButton({
  job,
  amountCharged,
}: {
  job: JobDetails;
  amountCharged?: string;
}) {
  const [showModal, { toggle: toggleModal }] = useBoolean();

  if (!biometricJobTypes.includes(job.ran_as_job_type)) {
    return null;
  }

  return (
    <div>
      <Button onClick={toggleModal} type="primary">
        Re-run Job
      </Button>
      <ModalWrapper isOpen={showModal} onClose={toggleModal} hideBackButton>
        <ReRun
          job={job}
          toggleModal={toggleModal}
          amountCharged={amountCharged}
        />
      </ModalWrapper>
    </div>
  );
}

function JobsShow() {
  const { environment } = useContext(DisplayEnvironment);
  const { id } = useParams<{ id: string }>();

  const { data: job, isLoading: isLoadingJob } = useJob(
    { id },
    { enabled: !!id },
  );
  const isBiometricJob = job
    ? biometricJobTypes.includes(job.job.ran_as_job_type)
    : null;
  const { data: jobFileLinks, isLoading: isLoadingJobFileLinks } =
    useJobFileLinks({ id }, { enabled: !!isBiometricJob });

  useEffect(() => {
    sessionStorage.setItem("url", `/job/${getEnvironment()}/${id}`);
    createInitialEnvironment();
  }, [id, environment]);

  if (isLoadingJob) {
    return <div className="loader" />;
  }

  const boundingBox = job!.ml_results?.passive_liveness?.metadata?.bounding_box;

  return (
    <div>
      <Breadcrumb
        items={[
          {
            title: (
              <Link to={`/admin/access_logs${window.location.search}`}>
                Access Logs
              </Link>
            ),
          },
          { title: `Job ${job!.job.job_id}` },
        ]}
      />
      <DisplayTitle
        environment={getEnvironment()}
        jobId={job!.job.job_id}
        jobType={job!.job.ran_as_job_type}
        partnerId={job!.job.partner_id}
        productType={job!.product_type}
        sdk={job!.job.sdk}
        sdkVersion={job!.job.sdk_version}
      />
      <DisplayReRunButton
        job={job!.job}
        amountCharged={job!.wallet_transaction?.amount}
      />
      <div>
        <Typography.Title className="h2" level={2}>
          Access Logs
        </Typography.Title>
        <DisplayLogs logs={job!.logs} />

        <Typography.Title className="h2" level={2}>
          Smile References
        </Typography.Title>
        <DisplayReferences references={job!.smile_references} />

        <Typography.Title className="h2" level={2}>
          Job Reviews
        </Typography.Title>
        <DisplayReviews reviews={job!.reviews} />

        <Typography.Title className="h2" level={2}>
          Inference Server Responses
        </Typography.Title>
        <DisplayMLResults mlResults={job!.ml_results} />

        <Typography.Title className="h2" level={2}>
          Related Jobs
        </Typography.Title>
        <DisplayRelatedJobs relatedJobs={job!.related_jobs} />
        {isBiometricJob && (
          <>
            <Typography.Title className="h2" level={2}>
              File Links (Biometric Jobs Only)
            </Typography.Title>
            {isLoadingJobFileLinks && <div>Loading...</div>}
            {jobFileLinks && <DisplayFiles fileLinks={jobFileLinks.files} />}
          </>
        )}
        {job!.images && (
          <>
            <Typography.Title className="h2" level={2}>
              Images
            </Typography.Title>
            <DisplayImages
              images={job!.images}
              boundingBox={boundingBox}
              mlResults={job!.ml_results}
            />
          </>
        )}
        {job!.wallet_transaction && (
          <>
            <Typography.Title className="h2" level={2}>
              Charges
            </Typography.Title>
            <DisplayCharges walletTransaction={job!.wallet_transaction} />
          </>
        )}
      </div>

      <FloatButton.BackTop />
    </div>
  );
}

export default JobsShow;
export {
  DisplayCharges,
  DisplayImages,
  DisplayReRunButton,
  DisplayReviews,
  DisplayLogs,
  DisplayTitle,
};
